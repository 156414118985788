<div class="scroll-x-- table-component table--padded" [class.loading-timed-out]="loadingTimedOut && ready">
    <!-- loadingTimedOut -->
    <ng-container *ngIf="loadingTimedOut && ready">
        <div class="loading-timed-out-spinner">
            <app-spinner></app-spinner>
        </div>
    </ng-container>
    <table class="table">
        <thead [class]="classes">
            <tr>
                <ng-container *ngFor="let head of localHeads">
                    <th 
                        [style.width]="head.width ? head.width : 'auto'"
                        [style.min-width]="head.minWidth ? head.minWidth : ''"
                        [class.saving]="loading || (!ready || notReady)" 
                        [class.sortable]="head.sortable" 
                        [class.shrink]="head.minWidth"
                        [class.center]="head.center"
                        class="saving--pre"
                        (click)="head.sortable && clickSort(head)">
                        <div class="position-relative">
                            {{head.name}}
                            <i *ngIf="head.sortable" class="sortable-icon" [ngClass]="getSortingClass(head)"></i>
                            <!-- column options -->
                            <ng-container *ngIf="hasColumnOptions && head.code === 'actions'">
                                <div class="column-options-button" (click)="openColumnOptionsModal()">
                                    <i class="im-icon im-icon-settings-sliders-vert"></i>
                                </div>
                            </ng-container>
                        </div>
                    </th>
                </ng-container>
            </tr>
        </thead>
        <tbody *ngIf="(ready && !notReady)" [class]="classes">
            <ng-container *ngFor="let item of data; trackBy: identify;index as $i">
                <tr [class.active]="item[id] === selected" [class.clickable]="clickable">
                    <ng-container *ngFor="let head of localHeads;trackBy: identify;">
                        <td 
                            [class.td--mw-0]="item[head.code]?.mw0" 
                            [class.td--actions]="item[head.code] && item[head.code].type === 'actions'" 
                            [class.td--flags]="item[head.code] && item[head.code].type === 'flags'" 
                            [class.shrink]="item[head.code] && (item[head.code].type === 'actions' || item[head.code].type === 'flags')" 
                            [class.linethrough]="item && item.linethrough && item[head.code] && head.code !== 'actions'" class="saving--pre {{(item[head.code]?.classList || '')}}"
                            [class.saving]="loading"
                            [class.editing]="item[head.code] && item[head.code].type === 'object' && item[head.code].object.editable && editing"
                        >

                            <!-- link -->
                            <ng-container *ngIf="item.routerLink">
                                <a (click)="false && $event.preventDefault(); clickData.emit({item: item, head: head.code})" draggable="false" [routerLink]="[item.routerLink]" queryParamsHandling="preserve" class="pad d-flex align-items-center">
                                    <ng-container [ngTemplateOutlet]="tdData" [ngTemplateOutletContext]="{item: item, head: head, $i: $i}"></ng-container>
                                </a>
                            </ng-container>

                            <!-- no link -->
                            <ng-container *ngIf="!item.routerLink">
                                <div (click)="$event.preventDefault(); clickData.emit({item: item, head: head.code})" class="pad d-flex align-items-center">
                                    <ng-container [ngTemplateOutlet]="tdData" [ngTemplateOutletContext]="{item: item, head: head, $i: $i}"></ng-container>
                                </div>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </tbody>
        <!-- shimmer -->
        <tbody *ngIf="(!ready || notReady)" class="shimmer-body">
            <ng-container *ngFor="let item of shimmerRows">
                <tr>
                    <td [attr.colspan]="localHeads.length"><app-shimmer></app-shimmer></td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
<!-- emptystate -->
<div *ngIf="!loading && data && !data.length && emptyState" class="mt-s empty-state-wrapper" [class]="classes">
    <app-empty-state [value]="emptyState"></app-empty-state>
</div>
<!-- pagination / export -->
<div *ngIf="(ready && !notReady) && data && data.length" class="row mt-l">
    <div class="col-4 d-flex align-items-center">
       <div *ngIf="exportLabel" class="d-flex w-100">
           <button (click)="clickExport.emit()" [class.disabled]="exportBusy" class="button-link fontsize-s export-btn">
               <i *ngIf="!exportBusy" class="im-icon im-icon-download"></i>
               <app-inline-spinner *ngIf="exportBusy" style="display: block; margin-right: 4px; transform: translateY(-1px);"></app-inline-spinner>
               <span class="underline">{{exportLabel}}</span>
           </button>
       </div>
        <div *ngIf="backToOverviewText && backToOverviewLink" class="d-flex w-100">
            <a class="link" [routerLink]="[backToOverviewLink]">{{ backToOverviewText }}</a>
        </div>
    </div>
    <div class="col-4">
        <div class="d-flex justify-content-center" *ngIf="totalRows > RPP" [class.saving]="loading">
            <app-pagination 
                [RPP]="RPP"
                [startRow]="startRow"
                [totalRows]="totalRows"
                (startRowChange)="startRowChange.emit($event)">
            </app-pagination>
        </div>
    </div>
    <div class="col-4 d-flex flex-row align-items-center">
        <div *ngIf="exportLabel" class="d-flex w-100 justify-content-end">
            <!--  -->
        </div>
    </div>
</div>


<!-- tData template -->
<ng-template #tdData let-item='item' let-head='head' let-$i="$i">
    <!-- default -->
    <ng-container *ngIf="!item[head.code] || !item[head.code].type">{{head.code === 'empty' ? '' : item[head.code] | display}}</ng-container>

    <!-- default type (with props) -->
    <ng-container *ngIf="item[head.code] && item[head.code].type === 'default'"><span [class.data-ellipsis]="item[head.code].ellipsis" [ngStyle]="{'color': item[head.code].color ? item[head.code].color : ''}" [class.has-stamps]="item[head.code]?.stamps?.length" [class]="item[head.code].classList" [class.low-opacity]="!item[head.code].value">{{item[head.code].value | display: item[head.code].placeholder }}</span></ng-container>

    <!-- title -->
    <ng-container *ngIf="item[head.code] && item[head.code].type === 'title'"><span [class.data-ellipsis]="item[head.code].ellipsis" [class.has-stamps]="item[head.code]?.stamps?.length" class="title">{{item[head.code].title | display: item[head.code].placeholder}}</span></ng-container>

    <!-- titleSubtitle -->
    <ng-container *ngIf="item[head.code] && item[head.code].type === 'titleSubtitle'"><div class="d-inline-flex flex-column title-subtitle"><span class="title">{{item[head.code].title}}</span><span class="subtitle" *ngIf="item[head.code].subtitle">{{item[head.code].subtitle}}</span></div></ng-container>

    <!-- ts -->
    <ng-container *ngIf="item[head.code] && item[head.code].type === 'ts'"><span style="white-space: nowrap;">{{item[head.code].ts | formatTs: item[head.code].format : item[head.code].unix || false}}</span></ng-container>

    <!-- tile -->
    <ng-container *ngIf="item[head.code] && item[head.code].type === 'tile'"><span [class.title]="!item[head.code].small">{{ item[head.code][head.code] }}</span> <span *ngIf="item[head.code].tile" class="tile" [class.primary-tile]="!item[head.code][head.code]" [class]="item[head.code].classList">{{ item[head.code].tile }}</span></ng-container>

    <!-- icon -->
    <ng-container *ngIf="item[head.code] && item[head.code].type === 'icon'"><i class="im-icon icon" [ngClass]="[item[head.code].icon, item[head.code].class]"></i></ng-container>

    <ng-container *ngIf="item[head.code] && item[head.code].type === 'link'">
        <a [ngClass]="item[head.code].class" (click)="onLinkClicked($event, item[head.code].link, item)">
            {{item[head.code].name}}
        </a>
    </ng-container>

    <!-- object -->
    <ng-container *ngIf="item[head.code] && item[head.code].type === 'object'">
        <span
            *ngIf="!editing || !item[head.code].object.editable"
            [class.title]="item[head.code].object[parameter]"
            [class.low-opacity]="!item[head.code].object[parameter]"
            [class]="item[head.code].classList"
        >
            {{ item[head.code].object[parameter] }}
        </span>
        <div
            *ngIf="editing && item[head.code].object.editable"
            class="justify-content-center w-100">
            <app-field-text
                type="integer"
                [errorTooltip]="item[head.code].error"
                [class.changed]="item[head.code].changed"
                [model]="item[head.code].object[parameter]"
                (modelChange)="onModelChange(item, head.code, $event)"
            ></app-field-text>
        </div>
    </ng-container>
    
    <!-- flags -->
    <ng-container *ngIf="item[head.code] && item[head.code].type === 'flags'">
        <div class="flags" *ngIf="item[head.code].flags?.length">
            <div *ngFor="let flag of item[head.code].flags" class="flag">
                <i 
                    placement="top-right" 
                    [autoClose]="false" 
                    tabindex="0" 
                    triggers="hover focus" 
                    [ngbTooltip]="tooltip" 
                    [container]="'body'"
                    [disableTooltip]="!flag.tooltip_title && !flag.tooltip_descr" 
                    tooltipClass="tooltip--flag"
                    [openDelay]="100" 
                    [closeDelay]="100"
                    class="im-icon im-icon-flag" 
                    [style.color]="flag.color">
                </i>
                <ng-template #tooltip>
                    <div class="title" *ngIf="flag.tooltip_title"><i class="im-icon im-icon-flag" [style.color]="flag.color"></i> {{flag.tooltip_title}}</div>
                    <div class="descr" *ngIf="flag.tooltip_descr">{{flag.tooltip_descr}}</div>
                </ng-template>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="item[head.code] && item[head.code].type === 'button'">
        <app-button
            class="align-left"
            classes="primary-default"
            icon="plus-round"
            [disabled]="item[head.code].disabled"
            [text]="item[head.code].text"
            (emitClick)="onButtonClicked(item)"
        ></app-button>
    </ng-container>

    <!-- actions -->
    <ng-container *ngIf="item[head.code] && item[head.code].type === 'actions'">
        <div *ngIf="item[head.code].actions && item[head.code].actions.length" class="action-button d-flex align-items-center actions position-relative" [class.active]="helper.action === $i" [ngbPopover]="popContent" [container]="'body'" placement="bottom-right" (shown)="helper.action = $i" (hidden)="helper.action = null" (click)="$event.stopPropagation(); $event.preventDefault();">
            <i class="im-icon im-icon-actions"></i>
            <ng-template #popContent>
                <ul>
                    <li class="position-relative" *ngFor="let action of item[head.code].actions; trackBy: identify;" appClickOutside (clickOutside)="helper.action = null" (click)="actionClick($event, action, $i, item)" [ngClass]="action.class || ''">
                        <i *ngIf="action.icon" class="im-icon im-icon-{{action.icon}}"></i> 
                        {{action.name}}
                    </li>
                </ul>
            </ng-template>
            <app-confirm-action-modal *ngIf="helper.actionItem" [open]="helper.confirm === $i" [confirmTitle]="helper.actionItem.confirmTitle" [confirmDescr]="helper.actionItem.confirmDescr" (confirmed)="clickAction.emit({item: item, action: helper.actionItem.code}); helper.confirm = null; helper.action = null;" (cancelled)="helper.confirm = null; helper.action = null;"></app-confirm-action-modal>
        </div>
    </ng-container>

    <!-- stamps -->
    <ng-container *ngIf="item[head.code]?.stamps?.length">
        <div class="stamps">
            <span class="spacer"></span>
            <ng-container *ngFor="let stamp of item[head.code].stamps">
                    <span class="stamp" [class]="stamp.class">{{stamp.value}}</span>
            </ng-container>
        </div>
    </ng-container>
</ng-template>